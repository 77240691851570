import { QueryHookOptions, gql, useQuery } from "@apollo/client";

export type GetProjectManageContextData = {
  statuses: {
    value: string;
    label: string;
  }[];
  categories: {
    value: string;
    label: string;
  }[];
  serviceTypes: {
    value: string;
    label: string;
  }[];
  billingTypes: {
    value: string;
    label: string;
  }[];
  roles: {
    value: string;
    label: string;
  }[];
  nonBillableCategories: {
    value: string;
    label: string;
  }[];
  managers: {
    id: string;
    firstName: string;
    lastName: string;
  }[];
};

const GET_PROJECT_MANAGE_CONTEXT = gql`
  query {
    statuses: project_status {
      value
      label
    }
    categories: project_category {
      value
      label
    }
    serviceTypes: project_service_type {
      value
      label
    }
    billingTypes: project_billing_type {
      value
      label
    }
    roles: project_employee_role {
      value
      label
    }
    nonBillableCategories: project_non_billable_category {
      value
      label
    }
    managers {
      id
      firstName: first_name
      lastName: last_name
    }
  }
`;

export const useProjectManageContext = (
  options?: QueryHookOptions<GetProjectManageContextData>
) => {
  return useQuery<GetProjectManageContextData>(
    GET_PROJECT_MANAGE_CONTEXT,
    options
  );
};
