import { Moment } from "moment";
import { ProjectEmployeeAssignment } from "./assignments";
import { Employee } from "./employee";

export type Timesheet = {
  id: string;
  taskId: string;
  description: string;
  startTime: string;
  endTime: string;
  minutes: number;
  billableMinutes: number | null;
  state: TimesheetStateType;
  stateInfo: string | null;
  assignmentId: string | null;
  assignment: Pick<
    ProjectEmployeeAssignment,
    "projectId" | "employeeId" | "employee" | "project" | "billableStatus"
  > | null;
  reviewedBy: string | null; // manager id
  manager: Employee | null;
  logType: TimesheetTypeValue;
  employeeId: string;
  readyToReview: boolean; // if true, log is not editable for employee
};

export enum TimesheetStateType {
  PENDING = "pending",
  APPROVED = "approved",
  DECLINED = "declined",
}

export type TimesheetType = {
  value: TimesheetTypeValue;
  label: string;
};
export type TimesheetTypeValue = "standard_hours" | string;

export type ActiveWeek = {
  start: string;
  end: string;
};

export type HolidayEvent = {
  id: string;
  description: "Public holiday" | string;
  summary: string; // name of the holiday
  start: { date: string }; // in format YYYY-MM-DD
  end: { date: string }; // in format YYYY-MM-DD
  // other fields irrelevant
};

export type DayOffFormType = {
  startTime: string;
  endTime: string;
  logType: TimesheetTypeValue;
  description: string;
};

export type ExceptionDayFormType = {
  reason: string;
};

export type TimesheetFormType = Pick<
  Timesheet,
  "description" | "startTime" | "endTime" | "taskId"
> & {
  duration?: { hours?: number; minutes?: number };
};
export type TimesheetRejectionFormType = Required<Pick<Timesheet, "stateInfo">>;
export type TimesheetApprovalFormType = {
  timesheets: Array<{
    id: Timesheet["id"];
    billableTime: Moment;
    nonBillableTime: Moment;
  }>;
};

export interface TimesheetExceptionDay {
  id: string;
  day: string;
  employeeId: string;
  managerId: string;
  enabled: boolean;
  reason?: string;
}

export type TimesheetsDayVariant =
  | "holiday"
  | "fullDayOff"
  | "workDay"
  | "weekendDay";
export type TimesheetsDayStatus = {
  isDeclined: boolean;
  isApproved: boolean;
  isPending: boolean;
};

export enum TimesheetsWeekState {
  WEEK_NOT_REVIEWED = "Week not reviewed",
  WEEK_FULLY_REJECTED = "Week fully rejected",
  WEEK_FULLY_APPROVED = "Week fully approved",
  WEEK_PARTLY_REVIEWED = "Week partly reviewed",
  WEEK_FULLY_REVIEWED = "Week fully reviewed",
}

// fallback config
export const TIMESHEETS_CONFIG = {
  BORDER_TIME: "05:59:00",
  MAX_MINUTES_WITHOUT_EXCEPTION_DAY: 480, // 8h without exception day
  MAX_MINUTES: 720, // 12h with exception day
  MIN_MINUTES: 480, // 8h, mock for now, value uniq for each employee based on contract
};
