const styles = {
  a: `
    flex 
    items-center
    w-full
    py-4 
    pl-9
    pr-5`,
  item: `
    flex 
    items-center
    rounded-lg
    hover:font-semibold`,
  activeItem: `
    bg-purple-1
    font-semibold`,
  activeIndicator: `
    absolute
    h-14
    w-2
    bg-purple-4`,
  icon: `
    [&>path]:stroke-white/50
    text-white/50`,
  iconActive: `
    [&>path]:stroke-white
    text-white`,
};

export default styles;
