import { ApolloError, gql, useMutation } from "@apollo/client";
import { GET_EMPLOYEE_TIMESHEETS_AND_ASSIGNMENTS } from "hooks";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { ActiveWeek, TimesheetExceptionDay } from "types/timesheets";

interface UpsertExceptionDayData {
  upsertedExceptionDay: TimesheetExceptionDay;
}

type UpsertExceptionDayVars = Required<
  Pick<TimesheetExceptionDay, "employeeId" | "day" | "reason" | "enabled">
> &
  Partial<Pick<TimesheetExceptionDay, "id">>;

const UPSERT_TIMESHEETS_EXCEPTION_DAY = gql`
  mutation upsertExceptionDay(
    $id: String
    $employeeId: String!
    $day: String!
    $reason: String!
    $enabled: Boolean!
  ) {
    upsertedExceptionDay: upsert_exception_day(
      id: $id
      employee_id: $employeeId
      day: $day
      reason: $reason
      enabled: $enabled
    ) {
      id
      employeeId: employee_id
      day
      enabled
      reason
    }
  }
`;

interface Props {
  employeeId: string;
  activeWeek: ActiveWeek;
}

export const useUpsertTimesheetExceptionDay = ({
  activeWeek,
  employeeId,
}: Props) => {
  const { start, end } = activeWeek;

  const [upsertTimesheetsExceptionDay] = useMutation<
    UpsertExceptionDayData,
    UpsertExceptionDayVars
  >(UPSERT_TIMESHEETS_EXCEPTION_DAY, {
    refetchQueries: [
      {
        query: GET_EMPLOYEE_TIMESHEETS_AND_ASSIGNMENTS,
        variables: {
          startDate: start,
          endDate: end,
          contractStartDate: start,
          contractEndDate: start,
          exceptionsStartDate: start,
          exceptionsEndDate: end,
          employeeId,
          isManager: true,
        },
      },
    ],
  });

  const upsertExceptionDay = useCallback(
    async (values: UpsertExceptionDayVars) => {
      try {
        await upsertTimesheetsExceptionDay({ variables: values });
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [upsertTimesheetsExceptionDay]
  );

  return { upsertExceptionDay };
};
