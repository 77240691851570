import { Header, HeaderGroup, Table, flexRender } from "@tanstack/react-table";
import { Loading } from "components";
import React, { Fragment, useMemo } from "react";
import { EmptyState, MobileFilters, Pagination } from "./components";
import styles from "./DesktopTable.styles";
import { TableProps } from "./Table";
import { TableData } from "./types";
import { IconButton } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { TableRowClickType } from "./hooks";

export type MobileTableProps<D extends TableData> = {
  table: Table<D>;
  disablePagination?: boolean;
  disableFilters?: boolean;
  handleOnGroupingRowClick?: TableRowClickType<D>;
} & Pick<
  TableProps<D>,
  | "columnMultiFilters"
  | "components"
  | "handleOnRowClick"
  | "searchProps"
  | "columnAdditionalFilters"
  | "name"
  | "loading"
>;

export type MappedHeadersType<D extends TableData> = Record<
  string,
  Header<D, unknown>
>;

export const MobileTable = <D extends TableData>({
  table,
  columnMultiFilters,
  columnAdditionalFilters,
  components,
  handleOnRowClick,
  handleOnGroupingRowClick,
  searchProps,
  name,
  loading,
  disablePagination = false,
  disableFilters = false,
}: MobileTableProps<D>) => {
  const rows = table.getRowModel().rows;
  const headerGroups = table.getHeaderGroups();

  const { headers, headersHighestDepth } = useMemo<{
    headers: MappedHeadersType<D>;
    headersHighestDepth: MappedHeadersType<D>;
  }>(() => {
    const mappedHeaders: HeaderGroup<D>["headers"] = headerGroups.reduce(
      (acc, { headers }) => [...acc, ...headers],
      [] as HeaderGroup<D>["headers"]
    );
    const headersHighestDepth: HeaderGroup<D>["headers"] =
      headerGroups[headerGroups.length - 1].headers;

    const mapHeaders = (items: HeaderGroup<D>["headers"]) =>
      items.reduce((acc, { id, ...rest }) => {
        return { ...acc, [id]: { id, ...rest } };
      }, {});

    return {
      headers: mapHeaders(mappedHeaders),
      headersHighestDepth: mapHeaders(headersHighestDepth),
    };
  }, [headerGroups]);

  return (
    <>
      <div className={styles.tableContainer}>
        {!disableFilters && (
          <MobileFilters<D>
            tableName={name}
            table={table}
            headers={headersHighestDepth}
            columnMultiFilters={columnMultiFilters}
            columnAdditionalFilters={columnAdditionalFilters}
            components={components}
            searchProps={searchProps}
          />
        )}
        {loading ? (
          <div className="h-full px-3 py-32">
            <Loading />
          </div>
        ) : (
          <table className="w-full">
            <tbody>
              {rows?.length ? (
                <>
                  {rows.map((row) => {
                    if (row.getIsGrouped()) {
                      const groupingCell = row
                        .getAllCells()
                        .find(
                          (cell) => cell.column.id === row.groupingColumnId
                        );
                      return (
                        <tr
                          key={row.id}
                          className="odd:bg-purple-7 even:bg-white"
                          onClick={() => {
                            handleOnGroupingRowClick?.(row);
                          }}
                        >
                          <td className="px-2 py-1">
                            <IconButton
                              onClick={(event) => {
                                event.stopPropagation();
                                row.getToggleExpandedHandler()?.();
                              }}
                              className="mr-2"
                            >
                              {row.getIsExpanded() ? (
                                <KeyboardArrowDown />
                              ) : (
                                <KeyboardArrowRight />
                              )}
                            </IconButton>
                            <span className="font-semibold">
                              {flexRender(
                                groupingCell!.column.columnDef.cell,
                                groupingCell!.getContext()
                              )}
                            </span>
                          </td>
                        </tr>
                      );
                    }

                    return (
                      <tr
                        key={row.id}
                        className="flex flex-col gap-3 px-5 py-6 odd:bg-purple-7 even:bg-white"
                        onClick={() => {
                          handleOnRowClick?.(row);
                        }}
                      >
                        {row.getVisibleCells().map((cell) => {
                          const cellStyles =
                            cell.column.columnDef.meta?.cellStyles(cell, true);
                          const header = headers?.[cell?.column?.id];
                          const parentHeaderName =
                            headers[
                              `${cell.column.depth}_${cell.column.parent?.id}_${cell.column.id}`
                            ]?.column.id;

                          return (
                            <Fragment key={cell.id}>
                              {!!parentHeaderName && (
                                <td className="text-xs uppercase text-purple-2">
                                  {parentHeaderName}:
                                </td>
                              )}
                              <td>
                                <div className="flex items-center justify-between first:text-xs first:font-semibold first:text-purple-2">
                                  {!header.column.columnDef
                                    .hideMobileHeader && (
                                    <div className="uppercase">
                                      {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                    </div>
                                  )}
                                  <div style={cellStyles}>
                                    {flexRender(
                                      cell.column.columnDef.cell,
                                      cell.getContext()
                                    )}
                                  </div>
                                </div>
                              </td>
                            </Fragment>
                          );
                        })}
                      </tr>
                    );
                  })}
                </>
              ) : (
                <tr className={styles.bodyRow}>
                  <td>
                    <EmptyState />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
      {!disablePagination &&
        !loading &&
        !!table.getFilteredRowModel().rows.length && (
          <Pagination<D> table={table} />
        )}
    </>
  );
};
