import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import { ProjectEmployeeAssignment } from "types/assignments";
import { Employee } from "types/employee";

type GetAssignmentsData = {
  assignments: ProjectEmployeeAssignment[];
  employees: (Pick<
    Employee,
    "id" | "firstName" | "lastName" | "picture" | "department" | "role"
  > & {
    personal?: {
      grade: {
        id: string;
        label: string;
      };
    };
  })[];
};

type GetAssignmentsVars = {
  now: string;
};

export const GET_ASSIGNMENTS = gql`
  query getAssignments($now: timestamptz) {
    assignments: project_employee_assignments(
      where: { start_date: { _lte: $now }, end_date: { _gte: $now } }
    ) {
      id
      startDate: start_date
      endDate: end_date
      billableStatus: billable_status
      fte
      roleInProject: role
      categoryOfNonBillable: category_of_non_billable
      reasonWhyNonBillable: reason_why_non_billable
      employeeId: employee_id
      projectId: project_id
      project {
        id
        name
        deliveryManagerId: supervisor_id
        category
      }
      employee {
        id
        firstName: first_name
        lastName: last_name
        picture
        department {
          id
          name
        }
        role {
          id
          name: erp_employee_role
        }
        personal: personal_details {
          id
          grade {
            id
            label
          }
        }
      }
    }
    employees(where: { status: { _eq: ACTIVE } }) {
      id
      firstName: first_name
      lastName: last_name
      picture
      department {
        id
        name
      }
      role {
        id
        name: erp_employee_role
      }
      personal: personal_details {
        id
        grade {
          id
          label
        }
      }
    }
  }
`;

export const useAssignments = () => {
  return useQuery<GetAssignmentsData, GetAssignmentsVars>(GET_ASSIGNMENTS, {
    variables: {
      now: moment().startOf("day").toISOString(),
    },
  });
};
