import { gql, useQuery } from "@apollo/client";
import { Employee } from "types/employee";

export interface GetEmployeesBasicData {
  employees: Pick<Employee, "id" | "firstName" | "lastName">[];
}

export const GET_EMPLOYEES_BASIC = gql`
  query GetEmployeesBasic {
    employees(order_by: { last_name: asc }) {
      id
      firstName: first_name
      lastName: last_name
    }
  }
`;

const GET_ACTIVE_EMPLOYEES_BASIC = gql`
  query GetActiveEmployeesBasic {
    employees(
      where: {
        _and: [{ status: { _is_null: false } }, { status: { _eq: ACTIVE } }]
      }
      order_by: { last_name: asc }
    ) {
      id
      firstName: first_name
      lastName: last_name
    }
  }
`;

export const useEmployeesBasic = ({ includeArchived = true } = {}) => {
  const query = includeArchived
    ? GET_EMPLOYEES_BASIC
    : GET_ACTIVE_EMPLOYEES_BASIC;
  return useQuery<GetEmployeesBasicData>(query);
};
