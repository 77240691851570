import { Checkbox } from "@mui/material";
import { ColumnFilterValue } from "@tanstack/react-table";
import { ColumnMultiFilterType } from "components/table/types";
import React, { FC } from "react";
import { CheckboxCheckIcon, CheckboxUncheckIcon } from "resources/icons";
import styles from "./ColumnMultiFilterItemsList.styles";

type ColumnMultiFilterItemsListProps = {
  checkedValues: ColumnFilterValue;
  handleCheck: (item: string) => void;
} & Pick<ColumnMultiFilterType, "items" | "columnId">;

export const ColumnMultiFilterItemsList: FC<
  ColumnMultiFilterItemsListProps
> = ({ checkedValues, handleCheck, items, columnId }) => {
  return (
    <ul
      role="listbox"
      aria-labelledby={`dropdownRoot-${columnId}`}
      className={styles.list}
    >
      {items.map((item) => {
        const isChecked = !!checkedValues?.[item];
        return (
          <li
            key={item}
            role="option"
            aria-selected={isChecked}
            className={styles.listOption}
            onClick={() => handleCheck(item)}
          >
            <Checkbox
              name={item}
              id={item}
              checked={isChecked}
              icon={<CheckboxUncheckIcon />}
              checkedIcon={<CheckboxCheckIcon />}
              classes={{
                root: "p-0",
              }}
            />
            <label htmlFor={item} className="ml-3 cursor-pointer pointer-events-none select-none">
              {item}
            </label>
          </li>
        );
      })}
    </ul>
  );
};
