import { gql, useQuery } from "@apollo/client";
import { useAuthContext } from "contexts";
import { ProjectEmployeeAssignment } from "types/assignments";
import { CONTRACTS_WORK_TIME_ENUM } from "types/employee";
import { ActiveWeek, Timesheet, TimesheetExceptionDay } from "types/timesheets";

export interface GetTimesheetsEmployeeVars {
  startDate: string;
  endDate: string;
  contractStartDate: string;
  contractEndDate: string;
  exceptionsStartDate: string;
  exceptionsEndDate: string;
  employeeId: string;
  isManager: boolean;
}

export interface EmployeeWorkTime {
  workTime: CONTRACTS_WORK_TIME_ENUM;
}

export interface GetTimesheetsEmployeeData {
  employee: {
    id: string;
    contracts?: EmployeeWorkTime[];
  };
  timesheets: Timesheet[];
  exceptionDays: TimesheetExceptionDay[];
  assignments: ProjectEmployeeAssignment[];
}

const timesheetFragment = gql`
  fragment TimesheetData on timesheets_work_logs {
    id
    startTime: start_time
    endTime: end_time
    minutes
    description
    manager {
      id
      firstName: first_name
      lastName: last_name
      email
      picture
    }
    state
    taskId: task_id
    employeeId: employee_id
    logType: log_type
    stateInfo: state_info
    assignmentId: assignment_id
    readyToReview: ready_to_review
  }
`;

const assignmentFragment = gql`
  fragment AssignmentData on project_employee_assignments {
    id
    employee {
      id
      firstName: first_name
      lastName: last_name
    }
    project {
      id
      name
    }
    startDate: start_date
    endDate: end_date
  }
`;

export const GET_EMPLOYEE_TIMESHEETS_AND_ASSIGNMENTS = gql`
  query timesheetsAndAssignments(
    $employeeId: uuid!
    $startDate: timestamptz!
    $endDate: timestamptz!
    $contractStartDate: timestamptz!
    $contractEndDate: timestamptz!
    $isManager: Boolean!
    $exceptionsStartDate: date
    $exceptionsEndDate: date
  ) {
    timesheets: timesheets_work_logs(
      order_by: { start_time: asc }
      where: {
        start_time: { _gte: $startDate }
        end_time: { _lte: $endDate }
        employee_id: { _eq: $employeeId }
      }
    ) {
      ...TimesheetData
      billableMinutes: billable_minutes @include(if: $isManager)
      assignment: project_employee_assignment {
        employeeId: employee_id
        employee {
          firstName: first_name
          lastName: last_name
        }
        projectId: project_id
        project {
          name
        }
        billableStatus: billable_status @include(if: $isManager)
      }
    }
    assignments: project_employee_assignments(
      where: {
        employee_id: { _eq: $employeeId }
        start_date: { _lte: $endDate }
        end_date: { _gte: $startDate }
      }
    ) {
      ...AssignmentData
      billableStatus: billable_status @include(if: $isManager)
      category_of_non_billable: category_of_non_billable @include(if: $isManager)
      reason_why_non_billable: reason_why_non_billable @include(if: $isManager)
    }
    exceptionDays: timesheets_exceptions_days(
      order_by: { day: asc }
      where: { employee_id: { _eq: $employeeId }, day: {_gte: $exceptionsStartDate, _lte: $exceptionsEndDate} }
    ) {
      id
      day
      employeeId: employee_id
      managerId: manager_id
      enabled
      reason @include(if: $isManager)
    }
    employee: employees_by_pk(id: $employeeId) {
      id
      contracts(
        where: {
          start_date: { _lte: $contractStartDate } 
          _or: [
            { end_date: { _gte: $contractEndDate } },
            { end_date: { _is_null: true } }
          ]
        }
      ) {
        workTime: work_time
      }
    }
  }
  ${timesheetFragment}, ${assignmentFragment}
`;

interface Props {
  employeeId: string | undefined;
  activeWeek: ActiveWeek;
}

export const useGetEmployeeTimesheets = ({ employeeId, activeWeek }: Props) => {
  const { start, end } = activeWeek;
  const { isManager } = useAuthContext();

  return useQuery<GetTimesheetsEmployeeData, GetTimesheetsEmployeeVars>(
    GET_EMPLOYEE_TIMESHEETS_AND_ASSIGNMENTS,
    {
      variables: {
        startDate: start,
        endDate: end,
        contractStartDate: start,
        contractEndDate: start,
        exceptionsStartDate: start,
        exceptionsEndDate: end,
        employeeId: employeeId!,
        isManager: isManager(),
      },
      skip: !employeeId,
      fetchPolicy: "network-only",
      pollInterval: 300000, // 5min
    }
  );
};
