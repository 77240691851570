import { useEmployee, useProject } from "hooks";
import { useMemo } from "react";
import { useGetParams } from "./components/content/components/hooks";
import { SELECTOR_TABS } from "./components/content/useBreadcrumbsSelectorContent";

export const useBreadcrumbsSelector = () => {
  const { employeeId, projectId, myEmployees } = useGetParams();
  const { data: employeeData, loading: employeeLoading } = useEmployee(
    employeeId,
    { skip: !employeeId }
  );
  const { data: projectData, loading: projectLoading } = useProject({
    variables: { projectId },
    skip: !projectId,
  });

  const initialSelectedValueEmployee = useMemo(
    () => ({
      id: employeeData?.employee?.id || "",
      value:
        employeeData?.employee?.firstName || employeeData?.employee?.lastName
          ? `${employeeData?.employee?.firstName} ${employeeData?.employee?.lastName}`
          : "",
      type: myEmployees ? SELECTOR_TABS.MY_EMPLOYEE : SELECTOR_TABS.EMPLOYEE,
      iconProps: {
        firstName: employeeData?.employee?.firstName || "",
        lastName: employeeData?.employee?.lastName || "",
        picture: employeeData?.employee?.picture || "",
      },
    }),
    [employeeData?.employee, myEmployees]
  );

  const initialSelectedValueProject = useMemo(
    () => ({
      id: projectData?.project?.id || "",
      value: projectData?.project?.name || "",
      type: SELECTOR_TABS.PROJECT,
    }),
    [projectData?.project]
  );

  return {
    initialSelectedValue: projectId
      ? initialSelectedValueProject
      : initialSelectedValueEmployee,
    loading: employeeLoading || projectLoading,
  };
};
