import { ApolloError, gql, useMutation } from "@apollo/client";
import { GET_EMPLOYEE_TIMESHEETS_AND_ASSIGNMENTS } from "hooks";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { ActiveWeek, Timesheet } from "types/timesheets";

type UpdateTimesheetsReadyToReviewVars = {
  workLogs: Pick<Timesheet, "id">[];
};

type UdateTimesheetsReadyToReviewData = {
  updatedTimesheets: { result: Timesheet[] };
};

const UPDATE_TIMESHEETS_READY_TO_REVIEW = gql`
  mutation updateTimesheetsReadyToReview($workLogs: [WorkLogInputType]!) {
    updatedTimesheets: ready_to_review(work_logs: $workLogs) {
      result {
        id
        startTime: start_time
        readyToReview: ready_to_review
      }
    }
  }
`;

interface Props {
  employeeId: string;
  activeWeek: ActiveWeek;
}

export const useUpdateTimesheetsReadyToReview = ({
  employeeId,
  activeWeek,
}: Props) => {
  const { start, end } = activeWeek;

  const [mutateTimesheetsReadyToReview] = useMutation<
    UdateTimesheetsReadyToReviewData,
    UpdateTimesheetsReadyToReviewVars
  >(UPDATE_TIMESHEETS_READY_TO_REVIEW, {
    refetchQueries: [
      {
        query: GET_EMPLOYEE_TIMESHEETS_AND_ASSIGNMENTS,
        variables: {
          startDate: start,
          endDate: end,
          contractStartDate: start,
          contractEndDate: start,
          exceptionsStartDate: start,
          exceptionsEndDate: end,
          employeeId,
          isManager: false,
        },
      },
    ],
  });

  const updateTimesheetsReadyToReview = useCallback(
    async (timesheets: Pick<Timesheet, "id">[]) => {
      try {
        await mutateTimesheetsReadyToReview({
          variables: { workLogs: timesheets },
        });
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [mutateTimesheetsReadyToReview]
  );

  return { updateTimesheetsReadyToReview };
};
