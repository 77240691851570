import moment from "moment";
import { ApolloError, gql, useMutation } from "@apollo/client";
import {
  GET_EMPLOYEE_CALENDAR,
  GET_EMPLOYEE_TIMESHEETS_AND_ASSIGNMENTS,
  GET_ABSENCES_INFO,
} from "hooks";
import camelCase from "lodash.camelcase";
import mapKeys from "lodash.mapkeys";
import { useTimeSheetsWeekContext } from "pages/timesheets/context";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { ActiveWeek, Timesheet } from "types/timesheets";

type UpsertDayOffVars = {
  logType: Timesheet["logType"];
  workLogs: {
    id?: string;
    index: number;
    start_time: Timesheet["startTime"];
    end_time: Timesheet["endTime"];
    description: Timesheet["description"];
    minutes: Timesheet["minutes"];
  }[];
};

type UpsertDayOffData = {
  upsertedDayOff: { result: Timesheet[] };
};

const UPSERT_DAY_OFF = gql`
  mutation upsertDayOff($logType: String!, $workLogs: [TimeLogInputType]!) {
    upsertedDayOff: submit_time_log(log_type: $logType, work_logs: $workLogs) {
      result {
        id
        startTime: start_time
        endTime: end_time
      }
    }
  }
`;

interface Props {
  employeeId: string;
  activeWeek: ActiveWeek;
}

export const useUpsertDayOff = ({ employeeId, activeWeek }: Props) => {
  const { start, end } = activeWeek;

  const { activeYear } = useTimeSheetsWeekContext();
  const startDate = moment([activeYear]).startOf("year").format("YYYY-MM-DD");
  const endDate = moment([activeYear]).endOf("year").format("YYYY-MM-DD");

  const [processUpsertDayOff, data] = useMutation<
    UpsertDayOffData,
    UpsertDayOffVars
  >(UPSERT_DAY_OFF, {
    // refetch needed instead of writing to cache (possibly due to returned type)
    refetchQueries: [
      {
        query: GET_EMPLOYEE_TIMESHEETS_AND_ASSIGNMENTS,
        variables: {
          startDate: start,
          endDate: end,
          contractStartDate: start,
          contractEndDate: start,
          exceptionsStartDate: start,
          exceptionsEndDate: end,
          employeeId,
          isManager: false,
        },
      },
      {
        query: GET_EMPLOYEE_CALENDAR,
        variables: { employeeId, startDate, endDate },
      },
      {
        query: GET_ABSENCES_INFO,
      },
    ],
  });

  const upsertDayOff = useCallback(
    async (vars: UpsertDayOffVars) => {
      try {
        await processUpsertDayOff({ variables: vars });
      } catch (ex) {
        let errors = undefined;
        const message = (ex as ApolloError).message.replaceAll(`'`, `"`);

        try {
          errors = JSON.parse(message);
          if (Array.isArray(errors)) {
            errors = errors.map((e) => mapKeys(e, (_, key) => camelCase(key)));
          } else {
            toast.error(message);
          }
        } catch (ex) {
          toast.error(message);
          errors = message;
        }
        return { errors };
      }
    },
    [processUpsertDayOff]
  );

  return { upsertDayOff, ...data };
};
