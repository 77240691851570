import React, { FC } from "react";
import { Link, generatePath } from "react-router-dom";
import styles from "./MenuItem.styles";
import { ExternalLinkIcon } from "resources/icons";

interface MenuItemProps {
  isActive: boolean;
  name: string;
  path: string;
  userId?: string;
  icon?: any;
  action?: any | null;
}

export const MenuItem: FC<MenuItemProps> = ({
  name,
  path,
  isActive,
  icon: Icon,
  userId,
  action,
}) => {
  const isNotConfigured = path === undefined;
  const isExternalLink = path?.startsWith("https://") || false;
  const href = generatePath(path, {
    employeeId: userId ?? " ",
  });
  return (
    <li className={`${styles.item} ${isActive ? styles.activeItem : ""}`}>
      {isActive ? <div className={styles.activeIndicator} /> : null}
      {!isNotConfigured && isExternalLink ? (
        <a
          href={path}
          target="_blank"
          rel="noreferrer"
          className={styles.a}
          data-cy={`menu-${name.replaceAll(" ", "-").toLowerCase()}`}
          {...(action && { onClick: action })}
        >
          <div>
            <Icon className={styles.icon} />
          </div>
          <span className="ml-3 text-white">{name}</span>
          <div>
            <ExternalLinkIcon className={`${styles.icon} mb-px ml-2`} />
          </div>
        </a>
      ) : null}
      {!isNotConfigured && !isExternalLink ? (
        <Link
          to={href}
          className={styles.a}
          data-cy={`menu-${name.replaceAll(" ", "-").toLowerCase()}`}
          {...(action && { onClick: action })}
        >
          <div>
            <Icon className={isActive ? styles.iconActive : styles.icon} />
          </div>
          <span className="ml-3 text-white">{name}</span>
        </Link>
      ) : null}
      {isNotConfigured ? (
        <Link
          to={href}
          className={styles.a}
          data-cy={`menu-${name.replaceAll(" ", "-").toLowerCase()}`}
          onClick={(e) => {
            e.preventDefault();
            console.log("item not configured");
          }}
        >
          <div>
            <Icon className={isActive ? styles.iconActive : styles.icon} />
          </div>
          <span className="ml-3 text-white line-through">{name}</span>
        </Link>
      ) : null}
    </li>
  );
};
