import { PROJECT_ICON_MAP } from "config/general";
import moment, { Moment } from "moment";
import { ProjectEmployeeAssignment } from "types/assignments";
import {
  HolidayEvent,
  Timesheet,
  TimesheetExceptionDay,
  TimesheetStateType,
  TimesheetsDayStatus,
  TimesheetsDayVariant,
  TimesheetsWeekState,
} from "types/timesheets";
import { tokenIsManager } from "./general.utils";

export const getWeekDatesRange = (date?: string) => ({
  start: moment(date).startOf("week").toISOString(),
  end: moment(date).endOf("week").toISOString(),
});

export const sumTotalMinutes = (timesheets: Timesheet[]): number => {
  const total = timesheets.reduce((sum, { minutes }) => {
    return sum + Number(minutes);
  }, 0);
  return total;
};

export const sumTotalBillableMinutes = (timesheets: Timesheet[]): number => {
  const total = timesheets.reduce((sum, t) => {
    return sum + Number(t.billableMinutes ?? 0);
  }, 0);
  return total;
};

export const formatTotalMinutes = (total: number): string => {
  const minutes = total % 60;
  const hours = (total - minutes) / 60;

  return `${hours}h${minutes > 0 ? " " + minutes + "m" : ""}`;
};

export const calculateTimeBetweenDates = (
  startDate: string | Moment,
  endDate: string | Moment
): number => {
  const duration = moment.duration(moment(endDate).diff(startDate));
  return duration.asMinutes();
};

export const getAssignmentsBetweenDates = (
  assignments: ProjectEmployeeAssignment[],
  start: string,
  end: string
): ProjectEmployeeAssignment[] =>
  assignments.filter(
    (ass) =>
      moment(ass.startDate).isSameOrBefore(end) &&
      moment(ass.endDate).isSameOrAfter(start)
  );

export const getTimesheetsBetweenDates = (
  timesheets: Timesheet[],
  start: string,
  end: string
): Timesheet[] =>
  timesheets.filter(
    (t) =>
      moment(t.startTime).isSameOrBefore(end) &&
      moment(t.endTime).isSameOrAfter(start)
  );

export const getOverlappingHoursTimesheets = (
  timesheets: Timesheet[],
  startTime: string,
  endTime: string
): Timesheet[] =>
  timesheets.filter(
    (t) =>
      // check for items starting between existing startTime & endTime
      (moment(startTime).isAfter(t.startTime) &&
        moment(startTime).isBefore(t.endTime)) ||
      // check for items ending between existing startTime & endTime
      (moment(endTime).isAfter(t.startTime) &&
        moment(startTime).isBefore(t.endTime)) ||
      // check for items starting before existing startTime and ending after endTime
      (moment(startTime).isBefore(t.startTime) &&
        moment(endTime).isAfter(t.endTime))
  );

export const getBorderDate = (timeConfig: string): Moment => {
  const weekday = moment().weekday();
  // current week's monday with time from config
  const borderDate = moment()
    .subtract(weekday, "day")
    .set({
      hours: +timeConfig.slice(0, 2),
      minutes: +timeConfig.slice(3, 5),
      seconds: +timeConfig.slice(6, 8),
    });
  return borderDate;
};

// manager can approve/reject:
// - pending logs:
//   - after borderDate on Mondays
//   - after border time on every 1st day of month
//   - marked as ready to review
// - pending day-offs
export const checkIfCanBeApproved = (
  timesheet: Timesheet,
  endOfDay: Moment,
  borderDate: Moment
): boolean => {
  const firstDayOfMonth = moment().startOf("month").add(12, "hour");
  const isPending = timesheet.state === TimesheetStateType.PENDING;
  if (
    timesheet.logType !== "standard_hours" ||
    moment(endOfDay).isBefore(firstDayOfMonth)
  ) {
    return isPending;
  }

  const isFirstDayOfMonth = moment().date() === 1;
  const firstDayOfMonthBorderDate = moment().set({
    hours: borderDate.hours(),
    minutes: borderDate.minutes(),
    seconds: borderDate.seconds(),
  });

  return (
    isPending &&
    (moment(endOfDay).isBefore(borderDate) ||
      (isFirstDayOfMonth && moment().isAfter(firstDayOfMonthBorderDate)) ||
      timesheet.readyToReview)
  );
};

// prev week is editable if current datetime is Monday before 12:00 (borderDate - set as Monday, 12:00 of current week)
const checkIsPrevWeekEditable = (borderDate: Moment) =>
  moment().isBefore(borderDate);

const checkWeekOfDate = (date: string) => {
  const startOfWeek = moment().startOf("week");
  const endOfWeek = moment().endOf("week");

  const isFromPrevWeek = moment(date).isBetween(
    moment().subtract(7, "day").startOf("week"),
    moment().subtract(7, "day").endOf("week"),
    undefined,
    "[)"
  );
  const isFromPastWeek = moment(date).isBefore(startOfWeek);
  const isFromCurrentWeek = moment(date).isBetween(
    startOfWeek,
    endOfWeek,
    undefined,
    "[)"
  );
  const isFromFutureWeek = moment(date).isAfter(endOfWeek);

  return {
    isFromPastWeek,
    isFromPrevWeek,
    isFromCurrentWeek,
    isFromFutureWeek,
  };
};

export const checkExceptionDay = (
  date: Moment | string,
  exceptionDays?: TimesheetExceptionDay[]
) => {
  const exceptionDay = (exceptionDays ?? []).find((e) =>
    moment(e.day).isSame(moment(date), "day")
  );
  const isExceptionDayEnabled = Boolean(exceptionDay?.enabled);
  return { exceptionDay, isExceptionDayEnabled };
};

export const canAddExceptionDay = (
  date: string,
  dayTimesheets: Timesheet[]
): boolean => {
  const { isFromPastWeek, isFromCurrentWeek } = checkWeekOfDate(date);
  const isDayReadyToReview = checkIsDayReadyToReview(dayTimesheets);
  return (
    isFromPastWeek ||
    (Boolean(isFromCurrentWeek && dayTimesheets.length) && isDayReadyToReview)
  );
};

export const canRequestExceptionDay = (
  date: string,
  dayTimesheets: Timesheet[],
  isExceptionDayEnabled: boolean,
  borderDate: Moment
): boolean => {
  const { isFromPastWeek, isFromCurrentWeek, isFromPrevWeek } =
    checkWeekOfDate(date);
  const isPrevWeekEditable = checkIsPrevWeekEditable(borderDate);
  const isDayReadyToReview = checkIsDayReadyToReview(dayTimesheets);

  return (
    !isExceptionDayEnabled &&
    ((isFromPrevWeek && !isPrevWeekEditable) ||
      (isFromPastWeek && !isFromPrevWeek) ||
      (isFromCurrentWeek && isDayReadyToReview))
  );
};

export const checkIfCanBeSetAsReadyToReview = (
  date: string,
  borderDate: Moment,
  isExceptionDayEnabled: boolean,
  dayTimesheets?: Timesheet[]
): boolean => {
  const filetredTimesheets = (dayTimesheets ?? []).filter(
    (t) => t.logType === "standard_hours"
  );
  if (!filetredTimesheets.length) {
    return false;
  }
  const isPrevWeekEditable = checkIsPrevWeekEditable(borderDate);
  const { isFromPrevWeek, isFromCurrentWeek } = checkWeekOfDate(date);
  const isAnyTimesheetDeclined = checkDayForDeclinedLogs(dayTimesheets);
  return Boolean(
    ((isFromPrevWeek && isPrevWeekEditable) ||
      isFromCurrentWeek ||
      isExceptionDayEnabled) &&
      !isAnyTimesheetDeclined
  );
};

export const checkIsDayReadyToReview = (
  dayTimesheets?: Timesheet[]
): boolean => {
  return Boolean(
    dayTimesheets?.length && dayTimesheets?.every((t) => t.readyToReview)
  );
};

export const checkDayForDeclinedLogs = (
  dayTimesheets?: Timesheet[]
): boolean => {
  return Boolean(
    dayTimesheets?.length &&
      dayTimesheets?.some((t) => t.state === TimesheetStateType.DECLINED)
  );
};

// ckeck if the assignment for the project/employee is active on this date
export const checkIsAssignmentActive = (
  assignment: ProjectEmployeeAssignment,
  date: string
): boolean =>
  !!assignment.id &&
  moment(assignment.startDate).isSameOrBefore(date, "day") &&
  moment(assignment.endDate).isSameOrAfter(date, "day");

// if adding is disabled, return message why
export const checkIfCanBeAdded = (args: {
  date: string;
  borderDate: Moment;
  exceptionDays: TimesheetExceptionDay[];
  isAssignmentActive: boolean;
  dayVariant: TimesheetsDayVariant;
  isManager: boolean;
  isDayReadyToReview: boolean;
}): { isDisabled: boolean; disabledMessage?: string } => {
  const {
    date,
    borderDate,
    exceptionDays,
    isAssignmentActive,
    dayVariant,
    isManager,
    isDayReadyToReview,
  } = args;

  if (isManager) {
    return { isDisabled: true };
  }

  const isDayOff = dayVariant === "fullDayOff";
  if (isDayOff) {
    return {
      isDisabled: true,
      disabledMessage: "Adding logs is not allowed for day with an absence",
    };
  }
  if (!isAssignmentActive) {
    return {
      isDisabled: true,
      disabledMessage: "No project assignment on this day",
    };
  }

  if (isDayReadyToReview) {
    return {
      isDisabled: true,
      disabledMessage: "Day is marked as ready for manager review",
    };
  }

  const isPrevWeekEditable = checkIsPrevWeekEditable(borderDate);
  const { isFromPastWeek, isFromPrevWeek, isFromCurrentWeek } =
    checkWeekOfDate(date);

  const isFromFuture = moment(date).isAfter(moment().endOf("day"));
  if (isFromFuture) {
    return {
      isDisabled: true,
      disabledMessage: "Adding logs for days in the future is not allowed",
    };
  }
  if ((isFromPrevWeek && isPrevWeekEditable) || isFromCurrentWeek) {
    return { isDisabled: false };
  }

  const { isExceptionDayEnabled } = checkExceptionDay(date, exceptionDays);
  if (isFromPastWeek) {
    return {
      isDisabled: !isExceptionDayEnabled,
      disabledMessage: isExceptionDayEnabled
        ? undefined
        : "Adding and updating logs for past days is not allowed",
    };
  }
  return { isDisabled: true, disabledMessage: "Adding logs is not allowed" };
};

export const canAddDayOff = (
  day: string,
  exceptionDays: TimesheetExceptionDay[],
  borderDate: Moment
) => {
  const { isExceptionDayEnabled } = checkExceptionDay(day, exceptionDays);
  const isPrevWeekEditable = checkIsPrevWeekEditable(borderDate);
  const {
    isFromPastWeek,
    isFromPrevWeek,
    isFromCurrentWeek,
    isFromFutureWeek,
  } = checkWeekOfDate(day);

  return (
    (isFromPastWeek && isExceptionDayEnabled) ||
    (isFromPrevWeek && isPrevWeekEditable) ||
    isFromCurrentWeek ||
    isFromFutureWeek
  );
};

// Manager can edit:
//   - state only of declined/approved logs/day-offs
// Employee can edit/delete:
//  - from past: pending/declined logs/day-offs if there is an exception day
//  - from current week and editable prev week: pending logs and pending/declined day-offs
//  - from future: pending/declined day-offs
// Employee can't edit/delete:
//   - approved logs/day-offs
export const checkIfCanBeEdited = (
  timesheet: Timesheet,
  isExceptionDayEnabled: boolean,
  borderDate: Moment
): boolean => {
  const isManager = tokenIsManager();
  const isDeclined = TimesheetStateType.DECLINED === timesheet.state;
  const isApproved = TimesheetStateType.APPROVED === timesheet.state;

  if (isManager) {
    return isDeclined || isApproved;
  }

  if (timesheet.readyToReview) {
    return false;
  }

  const isPrevWeekEditable = checkIsPrevWeekEditable(borderDate);
  const {
    isFromPastWeek,
    isFromPrevWeek,
    isFromCurrentWeek,
    isFromFutureWeek,
  } = checkWeekOfDate(timesheet.startTime);

  switch (timesheet.state) {
    case TimesheetStateType.PENDING: {
      return (
        (isPrevWeekEditable && isFromPrevWeek) ||
        (isFromPastWeek && isExceptionDayEnabled) ||
        isFromCurrentWeek ||
        isFromFutureWeek
      );
    }
    case TimesheetStateType.DECLINED: {
      const isDayOff = timesheet.logType !== "standard_hours";
      if (isDayOff) {
        return (
          (isPrevWeekEditable && isFromPrevWeek) ||
          (isFromPastWeek && isExceptionDayEnabled) ||
          isFromCurrentWeek ||
          isFromFutureWeek
        );
      }

      return (isFromPastWeek || isFromCurrentWeek) && isExceptionDayEnabled;
    }
    case TimesheetStateType.APPROVED:
      return false;
  }
};

export const transformLogEndTime = (
  time: Moment,
  variant: "add" | "subtract"
): Moment => {
  const isSecondMissing = time.format("ss:SSS").includes("59:000");
  if (variant === "add" && isSecondMissing) {
    return time.add(1, "second");
  }
  if (variant === "subtract" && !isSecondMissing) {
    return time.subtract(1, "second");
  }
  return time;
};

// start and ent times are transformed to handle logs overflowing to next day
// overflowing log is displayed in both days and is split into two parts by midnight
export const transformLogTimes = (
  timesheet: Timesheet,
  date: string
): { startTime: Moment; endTime: Moment; minutes: number } => {
  // start
  const startOfDay = moment(date).startOf("day");
  const startTime = moment(timesheet.startTime).isBefore(startOfDay)
    ? startOfDay
    : moment(timesheet.startTime);

  // end
  const endOfDay = moment(date).endOf("day").subtract(999, "millisecond");
  const endTime = moment(timesheet.endTime).isAfter(endOfDay)
    ? endOfDay
    : moment(timesheet.endTime);

  const transformedEndTime = transformLogEndTime(endTime, "add");

  const minutes = calculateTimeBetweenDates(startTime, endTime);

  return { startTime, endTime: transformedEndTime, minutes };
};

export const getHours = (startDate: string, endDate: string): number => {
  const duration = moment.duration(moment(endDate).diff(startDate));
  const hours = duration.asHours();
  return hours;
};

export const transformDatetimeToMinutes = (time: Moment): number => {
  const hours = time.get("hour");
  const minutes = time.get("minute");
  return hours * 60 + minutes;
};

export const getProjectIcon = () => PROJECT_ICON_MAP["general"];

export const getTimesheetsDayVariant = (
  date: string,
  dayTimesheets: Timesheet[],
  holiday: HolidayEvent | undefined
): TimesheetsDayVariant => {
  const fullDayOff = (dayTimesheets ?? []).find(
    (d) => d.logType !== "standard_hours"
  );
  const isWeekend = [5, 6].includes(moment(date).weekday()); // saturday or sunday
  return holiday
    ? "holiday"
    : fullDayOff
    ? "fullDayOff"
    : isWeekend
    ? "weekendDay"
    : "workDay";
};

export const getTimesheetsDayStatus = (
  dayTimesheets: Timesheet[]
): TimesheetsDayStatus => {
  const isPending = Boolean(
    dayTimesheets.find((t) => t.state === TimesheetStateType.PENDING)
  );
  const isApproved =
    dayTimesheets.length > 0 &&
    dayTimesheets.every((t) => t.state === TimesheetStateType.APPROVED);
  const isDeclined = Boolean(
    dayTimesheets.find((t) => t.state === TimesheetStateType.DECLINED)
  );
  return { isPending, isDeclined, isApproved };
};

export const getTimesheetsDayVariantStyles = (
  variant: TimesheetsDayVariant,
  status: TimesheetsDayStatus
) => {
  const { isDeclined, isApproved, isPending } = status;
  switch (variant) {
    case "fullDayOff": {
      return `${
        isDeclined
          ? "bg-red-3"
          : isApproved
          ? "bg-purple-4 text-white"
          : isPending
          ? "bg-orange-3"
          : "bg-purple-9 md:bg-purple-7"
      }`;
    }
    case "holiday": {
      return `${
        isDeclined
          ? "bg-red-3"
          : isApproved
          ? "bg-green-4"
          : isPending
          ? "bg-orange-3"
          : "bg-purple-8"
      }`;
    }
    case "workDay": {
      return `${
        isDeclined
          ? "bg-red-3"
          : isApproved
          ? "bg-green-4"
          : isPending
          ? "bg-orange-3"
          : "bg-purple-9 md:bg-purple-7"
      }`;
    }
    case "weekendDay": {
      return `${
        isDeclined
          ? "bg-red-3"
          : isApproved
          ? "bg-green-4"
          : isPending
          ? "bg-orange-3"
          : "bg-purple-6"
      }`;
    }
  }
};

export const getWeekState = (timesheets: Timesheet[]): TimesheetsWeekState => {
  if (timesheets.length > 0) {
    const isFullyApproved = timesheets.every(
      (t) => t.state === TimesheetStateType.APPROVED
    );
    if (isFullyApproved) {
      return TimesheetsWeekState.WEEK_FULLY_APPROVED;
    }

    const isFullyRejected = timesheets.every(
      (t) => t.state === TimesheetStateType.DECLINED
    );
    if (isFullyRejected) {
      return TimesheetsWeekState.WEEK_FULLY_REJECTED;
    }

    const isNotReviewed = timesheets.every(
      (t) => t.state === TimesheetStateType.PENDING
    );
    if (isNotReviewed) {
      return TimesheetsWeekState.WEEK_NOT_REVIEWED;
    }

    const isFullyReviewed = timesheets.every((t) =>
      [TimesheetStateType.APPROVED, TimesheetStateType.DECLINED].includes(
        t.state
      )
    );
    if (isFullyReviewed) {
      return TimesheetsWeekState.WEEK_FULLY_REVIEWED;
    }

    return TimesheetsWeekState.WEEK_PARTLY_REVIEWED;
  }
  return TimesheetsWeekState.WEEK_NOT_REVIEWED;
};
