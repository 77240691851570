import { ModalRefType } from "components";
import { useDeleteTimesheet, useUpsertTimesheet } from "hooks";
import sortBy from "lodash.sortby";
import moment from "moment";
import { useCallback, useMemo, useRef } from "react";
import { toast } from "react-toastify";
import { TimesheetFormType } from "types/timesheets";
import {
  getTimesheetsBetweenDates,
  transformLogEndTime,
} from "utils/timesheets.utils";
import {
  FormCommonPropsType,
  formInitialData,
  useTimeSheetsContext,
  useTimeSheetsWeekContext,
} from "../../../context";

export enum SUBMIT_FUNC_TYPE {
  SAVE = "SAVE",
  DELETE = "DELETE",
}
type UseTimesheetFormProps = FormCommonPropsType;
export const useTimesheetForm = ({
  handleModalClose,
  formData = formInitialData,
}: UseTimesheetFormProps) => {
  const modalRef = useRef<ModalRefType>(null);

  const { date, assignment, timesheets } = formData;
  const { activeWeek, activeWeekTimesheets, employeeId } =
    useTimeSheetsWeekContext();
  const { timesheetsConfig } = useTimeSheetsContext();

  const dayTimesheets = getTimesheetsBetweenDates(
    activeWeekTimesheets.filter((t) => t.logType === "standard_hours"),
    moment(date).startOf("day").toISOString(),
    moment(date).endOf("day").toISOString()
  );
  // if there are some other worklogs for selected day, we need to set startTime as endTime from the last log
  const lastDayTimesheetEndTime = sortBy(dayTimesheets, ["startTime"])?.at(
    -1
  )?.endTime;

  const timesheet = timesheets?.[0];

  const initialValues = useMemo<TimesheetFormType>(() => {
    const startTime =
      timesheet?.startTime ||
      lastDayTimesheetEndTime ||
      moment(date).set({ hour: 8, minute: 0 }).toISOString();
    const transformedStartTime = transformLogEndTime(
      moment(startTime),
      "add"
    ).toISOString();

    // if there is no endTime, we need to check if startTime exists to set endTime at least one hour later than startTime
    // in default case endTime is equal to 16:00
    const endTime =
      timesheet?.endTime ||
      (lastDayTimesheetEndTime &&
        moment(lastDayTimesheetEndTime).add(1, "h").toISOString()) ||
      moment(date).set({ hour: 16, minute: 0 }).toISOString();
    const transformedEndTime = transformLogEndTime(
      moment(endTime),
      "add"
    ).toISOString();

    return {
      startTime: transformedStartTime,
      endTime: transformedEndTime,
      description: timesheet?.description || "",
      taskId: timesheet?.taskId || "",
    };
  }, [
    date,
    lastDayTimesheetEndTime,
    timesheet?.description,
    timesheet?.endTime,
    timesheet?.startTime,
    timesheet?.taskId,
  ]);

  const { deleteTimesheet, loading: isDeleteTimesheetLoading } =
    useDeleteTimesheet({ employeeId });
  const { upsertTimesheet, loading: isUpsertTimesheetLoading } =
    useUpsertTimesheet({ employeeId, activeWeek });

  const handleSubmit = useCallback(
    async (values: TimesheetFormType) => {
      const mappedValues = {
        description: values.description,
        start_time: moment(values.startTime).toISOString(),
        end_time: transformLogEndTime(
          moment(values.endTime),
          "subtract"
        ).toISOString(),
        task_id: values?.taskId || "",
      };

      const data = await upsertTimesheet({
        assignmentId: assignment?.id || "",
        logType: "standard_hours",
        workLogs: [
          {
            ...(timesheet && { id: timesheet?.id }),
            index: 0,
            ...mappedValues,
          },
        ],
      });
      if (data?.errors[0]) {
        const error = data.errors[0].assignment || data.errors[0].gracePeriod;
        if (error) {
          toast.error(error);
        } else {
          return {
            ...data.errors[0], // return errors to show them under inputs
          };
        }
      } else {
        handleModalClose();
      }
    },
    [handleModalClose, assignment, timesheet, upsertTimesheet]
  );

  const handleDeleteTimesheet = useCallback(async () => {
    if (timesheet) {
      await deleteTimesheet(timesheet.id);
      handleModalClose();
    }
  }, [deleteTimesheet, handleModalClose, timesheet]);

  return {
    handleDeleteTimesheet,
    handleSubmit,
    initialValues,
    MAX_MINUTES: formData?.exceptionDay?.enabled
      ? timesheetsConfig.maxMinutes // with exception enabled
      : timesheetsConfig.maxMinutesWithoutExceptionDay,
    modalRef,
    activeWeekTimesheets,
    timesheet,
    date,
    assignment,
    isUpsertTimesheetLoading,
    isDeleteTimesheetLoading,
  };
};
