import {
  Table as TableType,
  TableOptions,
  TableState,
  Updater,
  CustomFiltersState,
} from "@tanstack/react-table";
import { SearchProps } from "components";
import { TABLE_ENUM } from "config/table-const";
import { useConfigContext } from "contexts";
import { useIsMobile } from "hooks";
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { DesktopTable } from "./DesktopTable";
import { MobileTable } from "./MobileTable";
import { TableRowClickType, useTable } from "./hooks";
import {
  ColumnAdditionalFilterType,
  ColumnMultiFilterType,
  TableData,
} from "./types";

export type TableProps<D extends TableData> = {
  name: TABLE_ENUM;
  data: TableOptions<D>["data"];
  columns: TableOptions<D>["columns"];
  state?: TableOptions<D>["state"];
  initialState?: TableOptions<D>["initialState"];
  columnMultiFilters?: ColumnMultiFilterType[];
  columnAdditionalFilters?: ColumnAdditionalFilterType[];
  components?: {
    FilterWrapperComponent?: FC<
      PropsWithChildren<{
        table: TableType<D>;
        onFiltersChange: (filters: CustomFiltersState) => void;
      }>
    >;
    SearchWrapperComponent?: FC<
      PropsWithChildren<{
        table: TableType<D>;
        onFiltersChange: (filters: CustomFiltersState) => void;
      }>
    >;
  };
  handleOnRowClick?: TableRowClickType<D>;
  handleOnGroupingRowClick?: TableRowClickType<D>;
  isMultiSortEvent?: (e: unknown) => boolean;
  searchProps?: Partial<SearchProps>;
  loading?: boolean;
  disablePagination?: boolean;
  disableFilters?: boolean;
};

export const Table = <D extends TableData>(props: TableProps<D>) => {
  const {
    columnMultiFilters,
    columnAdditionalFilters,
    components,
    handleOnRowClick,
    handleOnGroupingRowClick,
    searchProps,
    name,
    loading,
    disablePagination = false,
    disableFilters = false,
    ...tableOptions
  } = props;
  const { table } = useTable<D>(tableOptions);
  const { isMobile } = useIsMobile();

  const { changeFilters, filters } = useConfigContext();

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!initialized) {
      if (filters && filters[name]) {
        table.setState({ ...table.getState(), ...filters[name] });
      }
      setInitialized(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [state, setState] = useState(() => table.getState());

  const onStateChange = useCallback(
    (updater: Updater<TableState>) => {
      const newState = typeof updater === "function" ? updater(state) : updater;
      setState(newState);
      changeFilters(newState, name);
    },
    [state, name, changeFilters]
  );

  table.setOptions((prev) => ({
    ...prev,
    state,
    onStateChange,
  }));

  return isMobile ? (
    <MobileTable
      name={name}
      table={table}
      columnMultiFilters={columnMultiFilters}
      columnAdditionalFilters={columnAdditionalFilters}
      components={components}
      handleOnRowClick={handleOnRowClick}
      handleOnGroupingRowClick={handleOnGroupingRowClick}
      searchProps={searchProps}
      loading={loading}
      disablePagination={disablePagination}
      disableFilters={disableFilters}
    />
  ) : (
    <DesktopTable
      name={name}
      table={table}
      columnMultiFilters={columnMultiFilters}
      columnAdditionalFilters={columnAdditionalFilters}
      components={components}
      handleOnRowClick={handleOnRowClick}
      handleOnGroupingRowClick={handleOnGroupingRowClick}
      searchProps={searchProps}
      loading={loading}
      disablePagination={disablePagination}
      disableFilters={disableFilters}
    />
  );
};
