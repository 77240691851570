import { gql, useQuery } from "@apollo/client";
import moment from "moment";
import { Project } from "types/assignments";

interface GetProjectsData {
  projects: Pick<
    Project,
    | "id"
    | "name"
    | "status"
    | "startDate"
    | "endDate"
    | "headcount"
    | "billableCount"
    | "nonBillableCount"
    | "assignments"
    | "deliveryManagerId"
  >[];
}

interface GetProjectsVars {
  startDate: string;
  endDate: string;
}

export const GET_PROJECTS = gql`
  query getProjects($startDate: timestamptz, $endDate: timestamptz) {
    projects(
      where: {
        _or: [
          { status: { _is_null: false } }
          {
            project_employee_assignments: {
              work_logs: {
                start_time: { _gte: $startDate }
                end_time: { _lte: $endDate }
              }
            }
          }
        ]
      }
      order_by: { name: asc }
    ) {
      id
      name
      status
      startDate: start_date
      endDate: end_date
      deliveryManagerId: supervisor_id
      headcount(args: {})
      billableCount: headcount(args: { billable: true })
      nonBillableCount: headcount(args: { billable: false })
      assignments: project_employee_assignments {
        billableStatus: billable_status
        employeeId: employee_id
      }
    }
  }
`;

export const useProjects = () => {
  return useQuery<GetProjectsData, GetProjectsVars>(GET_PROJECTS, {
    variables: {
      startDate: moment().startOf("month").toISOString(),
      endDate: moment().endOf("month").toISOString(),
    },
  });
};
