import { CustomFilterFn, FilterFns } from "@tanstack/react-table";
import moment from "moment";
import { TableData } from "components/table/types";
import { isArray } from "lodash";
import { TimesheetsResponseData } from "pages/timesheets/hooks";
import { getWorkingsDaysFromPeriod } from "pages/timesheets/hooks/useTimesheetsTableColumns/utils";
import {
  CONTRACTS_WORK_TIME_ENUM,
  WORK_TIME_FRACTION_VALUE,
} from "types/employee";

const minutesPerHour = 60;
const formatTime = (reportedHours: string = ""): number => {
  const [hours, minutes] = reportedHours
    .replace("h", "")
    .replace("m", "")
    .split(" ");
  return +(hours ?? 0) * minutesPerHour + +(minutes ?? 0);
};

const includesFilterValue = (
  filterValue: Record<string, string>,
  columnValue: string
) => Object.keys(filterValue || {})?.some((key) => key?.includes(columnValue));

export const customFilterFns: Record<
  keyof FilterFns<TableData>,
  CustomFilterFn<TableData>
> = {
  columnMultiFilter: (row, columnId, filterValue) => {
    const columnValue: string | string[] = row.getValue(columnId);
    return isArray(columnValue)
      ? columnValue.some((val) => filterValue[val])
      : Boolean(filterValue[columnValue]);
  },
  employeeFilter: (row, columnId, filterValue) => {
    const columnValue: string | string[] = row.getValue(columnId);
    return isArray(columnValue)
      ? columnValue.some((val) => includesFilterValue(filterValue, val))
      : Boolean(includesFilterValue(filterValue, columnValue));
  },
  projectsFilter: (row, columnId, filterValue) => {
    const columnValue: TimesheetsResponseData["projects"] =
      row.getValue(columnId);
    return columnValue?.some(({ name }) => filterValue?.[name]) || false;
  },
  myEmployeeFilter: (row, columnId, _, __, customProps) => {
    const values = customProps;
    const columnValue: string | undefined = row.getValue(columnId);
    return values.includes(columnValue) || false;
  },
  myProjectsFilter: (row, _, __, ___, managerId) => {
    return (
      row.original.deliveryManagerId === managerId ||
      row.original.project?.deliveryManagerId === managerId
    );
  },
  currentAssignmentFilter: (row) => {
    return (
      moment(row.original.startDate).startOf("day") <=
        moment().startOf("day") &&
      moment(row.original.endDate).startOf("day") >= moment().startOf("day")
    );
  },
  missingApprovedHours: (row, columnId) => {
    const totalReportedHours = row.original.total_reported_hours;
    const totalHoursApproved: string | undefined = row.getValue(columnId);
    return formatTime(totalReportedHours) > formatTime(totalHoursApproved);
  },
  missingReportedHoursFilter: (
    row,
    _columnId,
    _filterValue,
    _addMeta,
    [missingReportedHoursStartDate, missingReportedHoursEndDate]
  ) => {
    const [hours, minutes] = (row.original.total_reported_hours || "")
      .replace("h", "")
      .replace("m", "")
      .split(" ");
    const contractTime: CONTRACTS_WORK_TIME_ENUM =
      row.original.work_time || CONTRACTS_WORK_TIME_ENUM.TIME_8_8;
    const workingDaysToToday = getWorkingsDaysFromPeriod(
      missingReportedHoursStartDate,
      missingReportedHoursEndDate
    );
    const toMinutes = WORK_TIME_FRACTION_VALUE[contractTime] * minutesPerHour;
    const expectedWorkingMinutesToToday = workingDaysToToday * toMinutes;
    const reportedWorkingMinutesToToday =
      +(hours || 0) * minutesPerHour +
      +(minutes || 0) +
      (row.original.daysOfAbsenceCount || 0) * toMinutes;
    return reportedWorkingMinutesToToday < expectedWorkingMinutesToToday
      ? true
      : false;
  },
};
