import { ApolloError, gql, useMutation } from "@apollo/client";
import { GET_EMPLOYEE_DETAILS } from "hooks";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { Employee } from "types/employee";

interface AssignEmployeeSupervisorData {
  assignEmployeeSupervisor: Pick<Employee, "id" | "directSupervisorId">;
}

interface AssignEmployeeSupervisorVars {
  employeeId: string;
}

const ASSIGN_EMPLOYEE_SUPERVISOR = gql`
  mutation assignEmployeeSupervisor($employeeId: String!) {
    assignEmployeeSupervisor: assign_employee_supervisor(
      employee_id: $employeeId
    ) {
      id
      directSupervisorId: direct_supervisor_id
    }
  }
`;

export const useAssignEmployeeSupervisor = () => {
  const [mutateEmployeeSupervisor] = useMutation<
    AssignEmployeeSupervisorData,
    AssignEmployeeSupervisorVars
  >(ASSIGN_EMPLOYEE_SUPERVISOR);

  // handle assign and unassign
  const assignEmployeeSupervisor = useCallback(
    async ({ employeeId }: AssignEmployeeSupervisorVars) => {
      try {
        await mutateEmployeeSupervisor({
          variables: { employeeId },
          refetchQueries: [
            { query: GET_EMPLOYEE_DETAILS, variables: { employeeId } },
          ],
        });
      } catch (ex) {
        toast.error((ex as ApolloError)?.message);
      }
    },
    [mutateEmployeeSupervisor]
  );

  return { assignEmployeeSupervisor };
};
