import { ApolloError } from "@apollo/client";
import { useAuthContext } from "contexts";
import {
  useGetEmployeeCalendar,
  useGetEmployeeTimesheets,
  useURLSearchParams,
} from "hooks";
import moment from "moment";
import React, {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import { ProjectEmployeeAssignment } from "types/assignments";
import { CONTRACTS_WORK_TIME_ENUM } from "types/employee";
import {
  ActiveWeek,
  HolidayEvent,
  Timesheet,
  TimesheetExceptionDay,
  TimesheetsDayStatus,
  TimesheetsDayVariant,
} from "types/timesheets";
import {
  getTimesheetsBetweenDates,
  getTimesheetsDayStatus,
  getTimesheetsDayVariant,
  getWeekDatesRange,
} from "utils/timesheets.utils";
import { useTimeSheetsContext } from "../timesheets/Timesheets.context";

type ChangeActiveWeekType = (date: string) => void;

type TimesheetsWeekContextType = {
  error?: ApolloError;
  loading: boolean;
  activeWeek: ActiveWeek;
  changeActiveWeek: ChangeActiveWeekType;
  employeeId: string;
  activeWeekData: Record<
    string, // date
    {
      dayStatus: TimesheetsDayStatus;
      dayVariant: TimesheetsDayVariant;
      dayTimesheets: Timesheet[];
      holiday: HolidayEvent | undefined;
    }
  >;
  activeWeekTimesheets: Timesheet[];
  activeWeekAssignments: ProjectEmployeeAssignment[];
  weekDates: string[];
  activeYear: number;
  employeeContractWorkTime: CONTRACTS_WORK_TIME_ENUM | undefined;
  exceptionDays: TimesheetExceptionDay[];
};

const TimeSheetsWeekContext = createContext<TimesheetsWeekContextType>(
  {} as TimesheetsWeekContextType
);

export const TimeSheetsWeekContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const history = useHistory();
  const { holidaysData } = useTimeSheetsContext();
  const { user, isManager } = useAuthContext();
  const { projectId } = useParams<{ projectId?: string }>();
  const { searchParams } = useURLSearchParams();

  const employeeId = isManager()
    ? searchParams.get("employeeId") || ""
    : user?.userId ?? "";
  const startDateParam = searchParams.get("startDate") || undefined;

  // default: current week
  const [activeWeek, setActiveWeek] = useState<ActiveWeek>(() => {
    return getWeekDatesRange(startDateParam);
  });
  const [activeYear, setActiveYear] = useState<number>(
    moment(startDateParam).year()
  );

  useEffect(() => {
    const dates = getWeekDatesRange(startDateParam);
    if (activeWeek.start !== dates.start) setActiveWeek(dates);
  }, [activeWeek.start, startDateParam]);

  const {
    data: employeeData,
    loading: employeeLoading,
    error: employeeError,
  } = useGetEmployeeTimesheets({ employeeId, activeWeek });

  const { refetch: refetchCalendar } = useGetEmployeeCalendar({
    employeeId,
    year: activeYear,
  });

  const changeActiveWeek = useCallback<ChangeActiveWeekType>(
    (clickedDate) => {
      const { start, end } = getWeekDatesRange(clickedDate);
      setActiveWeek({ start, end });
      searchParams.set("startDate", start);
      history.push({ search: `?${searchParams}` });
      const year = moment(start).year();
      if (year !== activeYear) {
        year !== activeYear && setActiveYear(year);
        const startDate = moment([year]).startOf("year").format("YYYY-MM-DD");
        const endDate = moment([year]).endOf("year").format("YYYY-MM-DD");
        refetchCalendar({ employeeId, startDate, endDate });
      }
    },
    [activeYear, searchParams, history, employeeId, refetchCalendar]
  );

  const weekDates = useMemo(() => {
    const dates: string[] = [];
    for (
      let i = 0;
      i <= moment(activeWeek.end).diff(moment(activeWeek.start), "days");
      i++
    ) {
      dates.push(moment(activeWeek.start).add(i, "days").toISOString());
    }
    return dates;
  }, [activeWeek]);

  const activeWeekAssignments = useMemo(() => {
    const assignments = employeeData?.assignments ?? [];
    const dummyAssignment = [
      // dummy assignment needed to show empty row with day-offs and holidays for weeks without any assignment
      {
        id: "",
        project: { id: "" },
        employee: { id: "" },
        startDate: activeWeek.start,
        endDate: activeWeek.end,
      } as ProjectEmployeeAssignment,
    ];

    return assignments.length > 0
      ? projectId
        ? [...assignments].sort(({ project }) =>
            project.id === projectId ? -1 : 0
          )
        : assignments
      : dummyAssignment;
  }, [activeWeek, employeeData?.assignments, projectId]);

  const activeWeekData = useMemo(() => {
    return weekDates.reduce((acc, date) => {
      const endOfDay = moment(date).endOf("day").toISOString();

      const dayTimesheets = getTimesheetsBetweenDates(
        employeeData?.timesheets ?? [],
        date,
        endOfDay
      );
      const holiday = holidaysData.find((h) =>
        moment(h.start.date).isSame(date)
      );
      const dayVariant = getTimesheetsDayVariant(date, dayTimesheets, holiday);
      const dayStatus = getTimesheetsDayStatus(dayTimesheets);
      return {
        ...acc,
        [date]: { dayStatus, dayVariant, dayTimesheets, holiday },
      };
    }, {});
  }, [employeeData?.timesheets, holidaysData, weekDates]);

  return (
    <TimeSheetsWeekContext.Provider
      value={{
        activeWeek,
        changeActiveWeek,
        activeWeekData,
        activeWeekTimesheets: employeeData?.timesheets ?? [],
        activeWeekAssignments,
        loading: employeeLoading,
        error: employeeError,
        weekDates,
        employeeId,
        activeYear,
        employeeContractWorkTime: employeeData?.employee?.contracts?.length
          ? employeeData?.employee.contracts[0].workTime
          : undefined,
        exceptionDays: employeeData?.exceptionDays ?? [],
      }}
    >
      {children}
    </TimeSheetsWeekContext.Provider>
  );
};

export const useTimeSheetsWeekContext = () => useContext(TimeSheetsWeekContext);
